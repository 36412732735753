import { Theme } from "@mui/material/styles";
import { alpha, SystemStyleObject } from "@mui/system";

const customInputStyles = {
  grey: (theme: Theme): SystemStyleObject<Theme> => ({
    color: theme.palette.grey[300],
  }),
  formControl: (theme: Theme): SystemStyleObject<Theme> => ({
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: 1,
      borderColor: "currentColor !important",
    },

    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: 2,
    },

    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: 2,
    },

    "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: `${alpha(theme.palette.text.primary, 0.8)} !important`,
    },
  }),
  input: (): SystemStyleObject<Theme> => ({
    fontSize: 16,
  }),
  disabled: (theme: Theme): SystemStyleObject<Theme> => ({
    color: theme.palette.grey[500],
    cursor: "not-allowed",
    WebkitTextFillColor: `${theme.palette.grey[500]} !important`,
  }),
  noPadding: {
    paddingRight: 0,
    paddingTop: 0,
  },
};

export default customInputStyles;
