import { useCallback } from "react";

import { useAccount, useNetwork, useSignMessage } from "wagmi";
import { SiweMessage } from "siwe";

import { API_ROUTES } from "config/routes";

import { metamaskWelcomeMessage } from "utils/blockchain";

import fetcher from "lib/fetchJson";

export const useMessage = () => {
  const { address } = useAccount();
  const { chain } = useNetwork();
  const { signMessageAsync } = useSignMessage();

  const getNonce = useCallback(async () => {
    try {
      return await fetcher(API_ROUTES.AUTH_NONCE);
    } catch {
      throw new Error("Nonce generation failed");
    }
  }, []);

  return async (walletAddress?: string, chainId?: number) => {
    const nonce = await getNonce();
    const message = new SiweMessage({
      domain: window.location.host,
      address: walletAddress ?? address,
      statement: metamaskWelcomeMessage(),
      uri: window.location.origin,
      version: "1",
      chainId: chainId ?? chain?.id,
      nonce,
    });
    const preparedMessage = message.prepareMessage();
    const signature = await signMessageAsync({
      message: preparedMessage,
    });

    return {
      message: preparedMessage,
      signature,
    };
  };
};
