import { FC, ReactElement } from "react";

import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import styles from "./styles";

type CustomTooltipProps = {
  text?: string;
  open?: boolean;
  onClose?: () => void;
  children?: ReactElement | string;
  content?: ReactElement;
  ownStyling?: boolean;
};

const CustomTooltip: FC<
  CustomTooltipProps & Omit<TooltipProps, "title" | "children" | "content">
> = ({
  text = "",
  placement = "top",
  open = undefined,
  onClose = undefined,
  children,
  content,
  ownStyling = false,
  PopperProps,
}) => {
  return (
    <Tooltip
      PopperProps={{
        sx: styles.popper,
        ...PopperProps,
      }}
      sx={ownStyling ? { "& .MuiTooltip-tooltip": styles.tooltip } : undefined}
      open={open}
      onClose={onClose}
      onClick={(e) => {
        e.preventDefault();
      }}
      arrow
      title={
        <Box sx={ownStyling ? {} : styles.content}>{text || children}</Box>
      }
      placement={placement}
    >
      {content ? <div>{content}</div> : <ErrorOutlineIcon sx={styles.icon} />}
    </Tooltip>
  );
};

export default CustomTooltip;
