import { z } from "zod";

export const emailSchema = z.object({
  email: z
    .string({ required_error: "This field is required - email" })
    .trim()
    .email({ message: "It's not a valid e-mail address" }),
  url: z.string().optional(),
});

export const signInSchema = z.object({
  email: z
    .string({ required_error: "This field is required - email" })
    .trim()
    .email({ message: "It's not a valid e-mail address" }),
  password: z.string({ required_error: "This field is required - password" }),
});

export const signUpSchema = z.object({
  email: z
    .string({ required_error: "This field is required - email" })
    .trim()
    .email({ message: "It's not a valid e-mail address" }),
  nickname: z
    .string({ required_error: "This field is required - nickname" })
    .trim(),
  url: z.string().optional(),
});
