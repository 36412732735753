import { useRouter } from "next/router";

import { FC, useState } from "react";
import { toast } from "react-toastify";

import { FormikProvider, useFormik } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { signUpSchema } from "validators/client/auth";

import CustomOutlinedInput from "components/CustomOutlinedInput";
import Button from "components/CustomButton";

import { AUTH_PHASES } from "enums/authPhases";

import { API_ROUTES } from "config/routes";

import { captureException } from "utils/errors";

import fetcher from "lib/fetchJson";

export type SignUpProps = {
  email: string;
};

const SignUp: FC<SignUpProps> = ({ email }) => {
  const initialState = {
    email: email,
    nickname: "",
  };

  const { asPath } = useRouter();
  const [authPhase, setAuthPhase] = useState(AUTH_PHASES.NICKNAME_SUBMIT);

  const handleSignUp = async (values) => {
    try {
      await fetcher(
        API_ROUTES.AUTH_OFF_CHAIN_SIGN_UP,
        {
          ...values,
          redirectUrl: asPath, // Come back to the same page after Magic Link
        },
        "POST",
      );
      setAuthPhase(AUTH_PHASES.ACTIVATION);
    } catch (e) {
      captureException(e);
      toast.error(e.data.message ?? "Something went wrong.");
    }
  };

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: toFormikValidationSchema(signUpSchema),
    validateOnChange: false,
    onSubmit: handleSignUp,
  });

  return (
    <FormikProvider value={formik}>
      <Box component="form" onSubmit={formik.handleSubmit}>
        {authPhase === AUTH_PHASES.NICKNAME_SUBMIT && (
          <>
            <Typography variant="h2" mb={2} textAlign={"center"}>
              Set your nickname
            </Typography>
            <Typography paragraph mb={0} textAlign={"center"}>
              Please provide your nickname to get started. This nickname will be
              displayed on your items, leaderboards and more!
            </Typography>
            <CustomOutlinedInput
              label="Nickname*"
              inputProps={{
                id: "nickname",
                placeholder: "Nickname",
                value: formik.values.nickname,
                onChange: formik.handleChange,
              }}
              formControlProps={{
                fullWidth: true,
                margin: "normal",
              }}
              error={formik.errors.nickname}
            />
            <Box display="grid" justifyContent="center" mt={1}>
              <Button
                isLoading={formik.isSubmitting}
                disabled={formik.isSubmitting}
                minWidth
                type="submit"
                sx={{ mt: 1 }}
              >
                {"CONTINUE"}
              </Button>
            </Box>
          </>
        )}

        {authPhase === AUTH_PHASES.ACTIVATION && (
          <>
            <Typography variant="h2" mb={2} textAlign={"center"}>
              {`Welcome, ${formik.values.nickname}!`}
            </Typography>

            <Typography paragraph mb={0} textAlign={"center"}>
              It's nice to have you on board! We have sent you a magic link to{" "}
              <b>{formik.values.email}</b>. Please check your inbox and click on
              the link to activate your account.
            </Typography>
          </>
        )}
      </Box>
    </FormikProvider>
  );
};

export default SignUp;
