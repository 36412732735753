import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import { selectAuthModalTab, setAuthModalTab } from "redux/slices/metamask";

import CustomLink from "components/CustomLink";
import AuthForm from "components/AuthForm";
import Modal from "components/Modal";
import Divider from "components/Divider";
import Connectors from "components/Connectors";

import { APP_ROUTES } from "config/routes";

export type AuthModalProps = {
  isOpen?: boolean;
  onClose?: () => void;
  hideCloseButton?: boolean;
};

const AuthModal: FC<AuthModalProps> = ({
  isOpen = false,
  hideCloseButton = false,
  onClose,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const activeTab = useSelector(selectAuthModalTab);

  const isLoginStage = activeTab === 0;

  const handleChange = (_: unknown, newValue: 0 | 1) => {
    dispatch(setAuthModalTab(newValue));
  };

  return (
    <Modal
      isOpen={isOpen}
      handleClose={onClose}
      title=""
      disableBackdropClick={false}
      hideCloseButton={hideCloseButton}
    >
      <Box
        display="grid"
        sx={{
          gridTemplateColumns: { xs: "1fr", md: "1fr auto 1fr" },
          columnGap: 4,
          mt: { xs: 4, md: 0 },
        }}
        justifyItems="center"
        maxWidth={900}
        minHeight={420}
        position="relative"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{ width: { sx: "100%", md: "70%" } }}
        >
          <Typography
            component="h3"
            variant="h3"
            mb={1}
            align="center"
            sx={{ textTransform: "uppercase" }}
          >
            Connect your wallet
          </Typography>
          <Typography paragraph mb={2.25} align="center">
            Sign in with your wallet
          </Typography>
          <Box mb={1}>
            <Connectors onSuccess={onClose} />
          </Box>
        </Box>
        <Divider isVertical={!isMobile} />

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{ width: { sx: "100%", md: "70%" } }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minWidth={"100%"}
          >
            <Tabs
              value={activeTab}
              onChange={handleChange}
              sx={{
                mb: 2,
                "& .MuiTabs-indicator": { display: "block" },
              }}
              textColor="inherit"
            >
              <Tab label="Welcome to Uniqly" sx={{ fontSize: 18 }} />
            </Tabs>
            <TabPanel
              sx={{
                width: "100%",
              }}
              value={activeTab}
              index={0}
            >
              <AuthForm isLoginState={isLoginStage} onClose={onClose} />
            </TabPanel>
          </Box>
        </Box>
      </Box>
      <Typography
        mt={4}
        display="block"
        component="span"
        variant="subtitle1"
        fontWeight="light"
      >
        By signing up or connecting a wallet, you confirm you have read and
        accepted Uniqly{" "}
        <CustomLink href={APP_ROUTES.TERMS} target="_blank" rel="noreferrer">
          Terms & Conditions
        </CustomLink>
        {" and "}
        <CustomLink href={APP_ROUTES.PRIVACY} target="_blank" rel="noreferrer">
          Privacy Policy
        </CustomLink>
        .
      </Typography>
    </Modal>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </Box>
  );
};

export default AuthModal;
