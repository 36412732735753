import { format, utcToZonedTime, formatInTimeZone } from "date-fns-tz";

export const secondsToTime = (
  value: number,
): {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
} => {
  let sec_num = value;

  const days = Math.floor(sec_num / 86400);
  sec_num -= days * 86400;

  const hours = Math.floor(sec_num / 3600) % 24;
  sec_num -= hours * 3600;

  const minutes = Math.floor(sec_num / 60) % 60;
  sec_num -= minutes * 60;

  const seconds = (sec_num % 60).toFixed();

  return {
    days: days.toString().padStart(2, "0"),
    hours: hours.toString().padStart(2, "0"),
    minutes: minutes.toString().padStart(2, "0"),
    seconds: seconds.toString().padStart(2, "0"),
  };
};

export const formatSeconds = (t: number): string | null => {
  if (!t) return null;
  const pattern = "dd/MM/yyyy hh:mm aa (z)";
  return formatSecondsWithPattern(t, pattern);
};

export const formatSecondsToShortDate = (t: number): string => {
  if (!t) return "";
  const pattern = "dd/MM/yyyy";
  return formatSecondsWithPattern(t, pattern);
};

const formatSecondsWithPattern = (t: number, pattern: string): string => {
  if (!t) return "";
  const date = new Date(t * 1000 - new Date().getTimezoneOffset() * 60000);
  const timeZone = "Europe/Berlin";
  const zonedDate = utcToZonedTime(date, timeZone);
  return format(zonedDate, pattern, { timeZone });
};

export const formatUTCSeconds = (t: number): string => {
  const date = new Date(t * 1000);
  const timeZone = "UTC";
  const zonedDate = utcToZonedTime(date, timeZone);
  const pattern = "dd/MM/yyyy hh:mm aa (z)";
  return format(zonedDate, pattern, { timeZone });
};

export const convertDateToUTC = (date: Date): Date => {
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
};

export const dateToUnixTime = (time: Date | number | string): number => {
  return new Date(time).getTime();
};

export const getDaysOffsetFromDate = (
  time: Date | number | string,
  offset = 2,
): Date => {
  return new Date(new Date(time).getTime() + 1000 * 60 * 60 * 24 * offset);
};

export const roundMinutes = (date: Date): Date => {
  date.setHours(date.getHours() + Math.round(date.getMinutes() / 60));
  date.setMinutes(0, 0, 0); // Resets also seconds and milliseconds
  return date;
};

export const formatUTCDate = (
  date: Date | string | number,
  dateFormat = "dd/MM/yyyy hh a",
) => `${format(convertDateToUTC(new Date(date)), dateFormat)} UTC`;

export const formattedTimezone = formatInTimeZone(
  new Date(),
  Intl.DateTimeFormat().resolvedOptions().timeZone,
  "zzz",
);
