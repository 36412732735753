import { FC, ReactElement } from "react";

import { FormikErrors } from "formik";

import Typography, { TypographyProps } from "@mui/material/Typography";
import FormControl, { FormControlProps } from "@mui/material/FormControl";
import OutlinedInput, { OutlinedInputProps } from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";

import CustomTooltip from "components/CustomTooltip";

import styles from "./styles";

interface CustomOutlinedInputProps {
  formControlProps?: FormControlProps;
  inputProps?: OutlinedInputProps;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  size?: OutlinedInputProps["size"];
  type?: OutlinedInputProps["type"];
  helperText?: string | ReactElement;
  label?: string | ReactElement;
  labelProps?: TypographyProps;
  labelTooltip?: string;
  description?: string | ReactElement;
}

const CustomOutlinedInput: FC<CustomOutlinedInputProps> = ({
  formControlProps = {},
  inputProps = {},
  error = "",
  size = "small",
  type = "text",
  helperText = "",
  label = "",
  labelProps = {},
  labelTooltip = "",
  description = "",
}) => {
  return (
    <FormControl
      fullWidth
      variant="outlined"
      {...formControlProps}
      sx={[
        ...(Array.isArray(formControlProps.sx)
          ? formControlProps.sx
          : [formControlProps.sx]),
        styles.formControl,
      ]}
    >
      {(label || description) && (
        <FormLabel>
          {label && (
            <Typography
              variant="body1"
              color="text.primary"
              gutterBottom={!description}
              display="flex"
              alignItems="center"
              {...labelProps}
            >
              {label}
              {labelTooltip && <CustomTooltip>{labelTooltip}</CustomTooltip>}
            </Typography>
          )}
          {description && (
            <Typography variant="body2" sx={styles.grey} gutterBottom>
              {description}
            </Typography>
          )}
        </FormLabel>
      )}
      <OutlinedInput
        type={type}
        autoComplete="off"
        size={size}
        {...inputProps}
        sx={[
          {
            "& .MuiOutlinedInput-input": styles.input,
          },
          ...(Array.isArray(inputProps.sx) ? inputProps.sx : [inputProps.sx]),
          {
            "& .Mui-disabled": styles.disabled,
          },
        ]}
      />
      <Typography variant="body2" mt={0.5}>
        {helperText}
      </Typography>
      {error && (
        <FormHelperText sx={{ m: 0 }} error>
          {String(error)}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default CustomOutlinedInput;
