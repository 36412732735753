import { FC, useEffect, useState } from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { COUNTER_SIZES } from "components/Counter/sizes";
import ClientOnly from "components/ClientOnly";

import { secondsToTime } from "utils/date";

import styles from "./styles";

export type CounterProps = {
  time: number | Date | string;
  size?: string;
  trigger?: () => void | null;
  isPlain?: boolean;
};

const Counter: FC<CounterProps> = ({
  time,
  size = COUNTER_SIZES.SMALL,
  trigger = null,
  isPlain = false,
}) => {
  const remainingTime = (+new Date(time) - +new Date()) / 1000;
  const [counter, setCounter] = useState<number>(remainingTime);

  useEffect(() => {
    if (counter === 0 && trigger !== null) trigger();

    if (counter < 0) {
      setCounter(0);
    }

    if (counter > 0) {
      const timer: ReturnType<typeof setInterval> = setInterval(
        () => setCounter(counter - 1),
        1000,
      );

      return () => clearInterval(timer);
    }
  }, [counter, trigger]);

  const { days, hours, minutes, seconds } = secondsToTime(counter);

  const formattedTime =
    days === "00"
      ? `${hours}H ${minutes}M ${seconds}S`
      : `${days}D ${hours}H ${minutes}M`;

  if (isPlain) {
    return (
      <ClientOnly>
        <>{formattedTime}</>
      </ClientOnly>
    );
  }

  if (size === COUNTER_SIZES.SMALL) {
    return (
      <Box sx={[{ maxWidth: { xs: 140 } }, styles.gradientBorder]}>
        <Box
          sx={[
            {
              p: { xs: 1, sm: 1 },
            },
            styles.mask,
          ]}
        >
          <ClientOnly>
            <Typography variant="body2" align="center">
              {formattedTime} left
            </Typography>
          </ClientOnly>
        </Box>
      </Box>
    );
  }

  if (size === COUNTER_SIZES.BIG) {
    return (
      <Box sx={[{ maxWidth: { xs: 160, sm: 190 } }, styles.gradientBorder]}>
        <Box
          sx={[
            {
              px: { xs: 0.5, sm: 1 },
              py: { xs: 1, sm: 2 },
            },
            styles.mask,
          ]}
        >
          <ClientOnly>
            <Typography component="h4" variant="h4" align="center">
              {formattedTime} left
            </Typography>
          </ClientOnly>
        </Box>
      </Box>
    );
  }

  if (size === COUNTER_SIZES.LARGE) {
    return (
      <Box sx={[{ maxWidth: 350, mx: "auto" }, styles.gradientBorder]}>
        <Box
          sx={[
            {
              px: { xs: 1, sm: 3 },
              py: { xs: 2, sm: 3 },
            },
            styles.mask,
          ]}
        >
          <ClientOnly>
            <Typography component="h3" variant="h3" align="center">
              {formattedTime} left
            </Typography>
          </ClientOnly>
        </Box>
      </Box>
    );
  }

  return null;
};

export default Counter;
