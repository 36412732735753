import { Theme } from "@mui/material/styles";
import { SystemStyleObject } from "@mui/system";

const counterStyles = {
  gradientBorder: (theme: Theme): SystemStyleObject<Theme> => ({
    backgroundImage: `linear-gradient(90deg, ${theme.palette.secondary.main}, ${theme.palette.secondary.dark})`,
    borderRadius: 0,
    padding: 0.25,
  }),
  mask: (theme: Theme): SystemStyleObject<Theme> => ({
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
    borderRadius: 0,
  }),
};

export default counterStyles;
