import { useRouter } from "next/router";

import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { FormikProvider, useFormik } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { emailSchema } from "validators/client/auth";

import SignUp from "./SignUp";
import SignIn from "./SignIn";

import CustomOutlinedInput from "components/CustomOutlinedInput";
import Button from "components/CustomButton";

import { AUTH_PHASES } from "enums/authPhases";

import { API_ROUTES } from "config/routes";

import { captureException } from "utils/errors";

import fetcher from "lib/fetchJson";

export type AuthFormProps = {
  isLoginState?: boolean;
  onClose?: () => void;
};

const initialState = {
  email: "",
  password: "",
  nickname: "",
};

const AuthForm: FC<AuthFormProps> = ({ isLoginState = true }) => {
  const { asPath } = useRouter();
  const [isRegistrationSubmitted, setRegistrationSubmitted] = useState(false);
  const [authPhase, setAuthPhase] = useState(AUTH_PHASES.EMAIL_SUBMIT);

  // Check if user exists. If yes, send magic link. If no, ask for nickname to continue sign up.
  const handleCheckAvailable = async (values) => {
    try {
      const result = (await fetcher(
        `${API_ROUTES.IS_USER_AVAILABLE}/?email=${encodeURIComponent(
          values.email,
        )}`,
      )) as { isAvailable: boolean };
      if (result.isAvailable === true) {
        setAuthPhase(AUTH_PHASES.NICKNAME_SUBMIT);
      } else {
        await fetcher(
          API_ROUTES.AUTH_MAGIC_LINK_GENERATE,
          {
            ...values,
            redirectUrl: asPath, // Come back to the same page after Magic Link
          },
          "POST",
        );
        setAuthPhase(AUTH_PHASES.SIGN_IN);
      }
    } catch (e) {
      captureException(e);
      toast.error(e.data.message ?? "Something went wrong.");
    }
  };

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: toFormikValidationSchema(emailSchema),
    validateOnChange: false,
    onSubmit: handleCheckAvailable,
  });

  useEffect(() => {
    setRegistrationSubmitted(false);
    formik.handleReset(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoginState]);

  return (
    <>
      {authPhase === AUTH_PHASES.EMAIL_SUBMIT && (
        <FormikProvider value={formik}>
          <Box component="form" onSubmit={formik.handleSubmit}>
            <>
              <Typography variant="h2" mb={2} textAlign={"center"}>
                Enter your email
              </Typography>
              <Typography paragraph mb={0} textAlign={"center"}>
                Enter your email to get started. We will send you a magic link
                to continue.
              </Typography>
              <CustomOutlinedInput
                label="Email*"
                inputProps={{
                  id: "email",
                  placeholder: "Email address",
                  value: formik.values.email,
                  onChange: formik.handleChange,
                  disabled: isRegistrationSubmitted,
                }}
                formControlProps={{
                  fullWidth: true,
                  margin: "normal",
                }}
                error={formik.errors.email}
              />
            </>

            <Box display="grid" justifyContent="center" mt={1}>
              <Button
                isLoading={formik.isSubmitting}
                disabled={formik.isSubmitting || isRegistrationSubmitted}
                minWidth
                type="submit"
                sx={{ mt: 1 }}
              >
                {"CONTINUE"}
              </Button>
            </Box>
          </Box>
        </FormikProvider>
      )}

      {authPhase === AUTH_PHASES.NICKNAME_SUBMIT && (
        <SignUp email={formik.values.email} />
      )}

      {authPhase === AUTH_PHASES.SIGN_IN && (
        <SignIn email={formik.values.email} />
      )}
    </>
  );
};

export default AuthForm;
