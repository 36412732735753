import { useRouter } from "next/router";
import Image from "next/image";

import { FC, useState } from "react";
import { toast } from "react-toastify";

import {
  useAccount,
  useConnect,
  useDisconnect,
  Address,
  Connector,
} from "wagmi";
import { useWeb3Modal } from "@web3modal/react";

import Box from "@mui/material/Box";

import { useAppDispatch } from "redux/store";
import { setLoggedInUser } from "redux/slices/metamask";

import CustomButton from "components/CustomButton";

import { useMessage } from "hooks/useMessage";

import { API_ROUTES } from "config/routes";

import fetcher from "lib/fetchJson";

type ConnectorsProps = {
  onSuccess?: () => void;
  onBeforeConnect?: () => void;
  onError?: (error?: string | undefined) => void;
  onClick?: (walletAddress?: string, chainId?: number) => void | Promise<void>;
  addressToConnect?: string | null;
};

const Connectors: FC<ConnectorsProps> = ({
  onSuccess,
  onBeforeConnect,
  onClick,
  onError,
  addressToConnect,
}) => {
  const dispatch = useAppDispatch();
  const { asPath } = useRouter();
  const { disconnectAsync } = useDisconnect();

  const signMessage = useMessage();
  const [isSigningIn, setIsSigningIn] = useState<boolean>(false);

  const { connectAsync, connectors, isLoading, pendingConnector } =
    useConnect();

  const { open } = useWeb3Modal();

  const { address, isConnected } = useAccount({
    onConnect: async (data) => {
      if (!data.address || data.isReconnected) {
        return;
      }
      const chainId = await data.connector?.getChainId();
      if (!chainId) {
        return;
      }
      handleSignIn(addressToConnect || data.address, chainId);
    },
  });

  const dAppLink =
    (process.env.NEXT_PUBLIC_APP_URL as string).replace(/(^\w+:|^)\/\//, "") +
    asPath;

  const handleGetUser = async (metamaskAddress) => {
    try {
      return await fetcher(`${API_ROUTES.USERS}/${metamaskAddress}`);
    } catch {
      return null;
    }
  };

  const handleSignUp = async (metamaskAddress) => {
    await fetcher(
      API_ROUTES.AUTH_ON_CHAIN_SIGN_UP,
      { metamaskAddress },
      "POST",
    );
  };

  const handleSignIn = async (
    walletAddress: string | Address,
    chainId?: number,
  ) => {
    try {
      onError && onError();

      if (onClick) {
        setIsSigningIn(true);
        await onClick(walletAddress, chainId);
        return;
      }
      const wAddress = walletAddress ?? address;
      if (!wAddress) {
        return;
      }

      setIsSigningIn(true);

      const { message, signature } = await signMessage(wAddress, chainId);

      const user = await handleGetUser(wAddress);

      if (!user) {
        await handleSignUp(wAddress);
      }

      // Verify signature
      const verifyRes = await fetcher(
        API_ROUTES.AUTH_ON_CHAIN_SIGN_IN,
        { message, signature },
        "POST",
      );

      // Verify if the user session has been set correctly
      const me = await fetcher(API_ROUTES.AUTH_ME);

      if (!me.isLoggedIn) {
        throw new Error("User session has not been set");
      }

      dispatch(
        setLoggedInUser({
          ...verifyRes,
          address,
        }),
      );
      onSuccess && onSuccess();
    } catch (error) {
      await Promise.reject({
        message:
          error?.message || error?.data?.message || "Something went wrong.",
      });
      onError &&
        onError(
          error?.message || error?.data?.message || "Something went wrong.",
        );
    } finally {
      setIsSigningIn(false);
    }
  };

  const handleConnect = async (connector: Connector) => {
    try {
      if (isConnected) {
        await disconnectAsync();
      }

      if (onBeforeConnect) {
        setIsSigningIn(true);
        await onBeforeConnect();
      }

      if (connector.id === "injected") {
        const { account, chain } = await connectAsync({ connector });
        return { account, chain };
      } else {
        await open();
      }
    } catch (error) {
      const message =
        error?.message || error?.data?.message || "Something went wrong.";
      if (onError) {
        onError(message);
        return;
      }
      toast.error(message);
    } finally {
      setIsSigningIn(false);
    }
  };

  return (
    <Box display="flex" justifyContent="center" flexDirection="column">
      {connectors
        .sort((connector) => (connector.id === "walletConnect" ? 1 : -1))
        .map((connector) =>
          connector.id === "injected" && !connector.ready ? (
            <CustomButton
              key={connector.id}
              href={`https://metamask.app.link/dapp/${dAppLink}`}
              target="_blank"
              sx={{ mb: 2, minHeight: 44 }}
            >
              <>
                <Image
                  src={`${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/icons/metamask.svg`}
                  alt="metamask-logo"
                  height="25"
                  width="35"
                />
                Metamask
              </>
            </CustomButton>
          ) : (
            <CustomButton
              disabled={!connector.ready || isLoading || isSigningIn}
              key={connector.id}
              onClick={() => handleConnect(connector)}
              sx={{ mb: 2, minHeight: 44 }}
              loaderLabel="connecting"
              isLoading={
                (isSigningIn && connector.id === "injected") ||
                (isLoading && connector.id === pendingConnector?.id)
              }
            >
              {[
                "MetaMask",
                "WalletConnect",
                "Rainbow",
                "Coinbase Wallet",
                "Rabby Wallet",
                "Trust Wallet",
                "Phantom",
              ].includes(connector.name) && (
                <Image
                  src={`${
                    process.env.NEXT_PUBLIC_CLOUDFRONT_URL
                  }/pages/icons/${connector.name.toLowerCase()}.svg`}
                  alt={`${connector.id}-logo`}
                  height="25"
                  width="35"
                />
              )}
              {connector.name.replace("Legacy", "")}
              {!connector.ready && " (unsupported)"}
            </CustomButton>
          ),
        )}
    </Box>
  );
};

export default Connectors;
