import { useRouter } from "next/router";

import { FC, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useQueryClient } from "react-query";

import { FormikProvider, useFormik } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";

import styles from "../styles";

import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";

import { signInSchema } from "validators/client/auth";

import { setAuthModalOpen, setLoggedInUser } from "redux/slices/metamask";

import CustomOutlinedInput from "components/CustomOutlinedInput";
import Button from "components/CustomButton";

import { API_ROUTES, APP_ROUTES } from "config/routes";

import fetcher from "lib/fetchJson";

export type SignInProps = {
  email: string;
};

const SignIn: FC<SignInProps> = ({ email }) => {
  const initialState = {
    email: email,
    password: "",
  };

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { asPath, push } = useRouter();
  const [showPassword, setShowPassword] = useState(false);
  const [passwordLogin, setPasswordLogin] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSignIn = async (values, { resetForm }) => {
    try {
      const data = await fetcher(
        API_ROUTES.AUTH_OFF_CHAIN_SIGN_IN,
        values,
        "POST",
      );
      dispatch(setLoggedInUser(data));
      resetForm();
      dispatch(setAuthModalOpen(false));
      await queryClient.invalidateQueries();
      if (asPath.includes(APP_ROUTES.PASSWORD_RESET)) {
        await push("/");
      }
      if (
        asPath.includes(APP_ROUTES.CHANGE_EMAIL) ||
        asPath.includes(APP_ROUTES.REQUEST_CONNECT_OFFCHAIN)
      ) {
        await push(APP_ROUTES.USER_PROFILE);
      }
      toast.success("Logged in successfully.");
    } catch (e) {
      toast.error(e.data.message ?? "Something went wrong.");
    }
  };

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: toFormikValidationSchema(signInSchema),
    validateOnChange: false,
    onSubmit: handleSignIn,
  });

  return (
    <FormikProvider value={formik}>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <>
          <Typography variant="h2" mb={2} textAlign={"center"}>
            Check your email!
          </Typography>
          <Typography paragraph textAlign={"center"}>
            We have sent you a magic link to <b>{formik.values.email}</b>
          </Typography>
          <Typography paragraph textAlign={"center"}>
            {`Please check your inbox and click on the link to log in.`}
          </Typography>
          <ButtonBase
            sx={{
              textAlign: "center",
              display: "block",
              width: "100%",
              textDecoration: "underline",
              backgroundImage:
                "linear-gradient(180deg, #ECA843 0%, #AD7E38 100%)",
              color: "transparent",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
            }}
            onClick={() => setPasswordLogin(true)}
          >
            <Typography variant="h4" textAlign={"center"}>
              {"Log in with password instead"}
            </Typography>
          </ButtonBase>
        </>
        {passwordLogin && (
          <>
            <CustomOutlinedInput
              label="Password*"
              inputProps={{
                id: "password",
                placeholder: "Password",
                value: formik.values.password,
                onChange: formik.handleChange,
                type: showPassword ? "text" : "password",
                sx: styles.passwordInput,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleShowPassword}
                      onMouseDown={(e) => {
                        e.preventDefault();
                      }}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOff sx={styles.passwordIcon} />
                      ) : (
                        <Visibility sx={styles.passwordIcon} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              formControlProps={{
                fullWidth: true,
                margin: "normal",
              }}
              error={formik.errors.password}
            />
            <Box display="grid" justifyContent="center" mt={1}>
              <Button
                isLoading={formik.isSubmitting}
                disabled={formik.isSubmitting}
                minWidth
                type="submit"
                sx={{ mt: 1 }}
              >
                {"CONTINUE"}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </FormikProvider>
  );
};

export default SignIn;
