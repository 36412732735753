import { Theme, alpha } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import { SystemStyleObject } from "@mui/system";

const customTooltipStyles = {
  content: (): SystemStyleObject<Theme> => ({
    padding: 2,
    margin: 0,
    zIndex: 999999,
  }),
  icon: (): SystemStyleObject<Theme> => ({
    fontSize: 14,
    marginLeft: 0,
    cursor: "pointer",
  }),
  popper: (theme: Theme): SystemStyleObject<Theme> => ({
    zIndex: 9999,
    [`& .${tooltipClasses.tooltip}`]: {
      p: 0,
      backgroundColor: theme.palette.text.primary,
      color: theme.palette.text.secondary,
      boxShadow: `0px 2px 7px ${alpha(theme.palette.background.default, 0.12)}`,
      fontSize: 12,
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.text.primary,
      fontSize: 26,
      left: -7, // (fontSize - 12) / 2
    },
  }),
  tooltip: (): SystemStyleObject<Theme> => ({
    padding: 0,
  }),
};

export default customTooltipStyles;
