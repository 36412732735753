import { Theme } from "@mui/material/styles";
import { SystemStyleObject } from "@mui/system";

const offchainLoginFormStyles = {
  passwordInput: (): SystemStyleObject<Theme> => ({
    "input::-ms-reveal, input::-ms-clear": {
      display: "none",
    },
  }),
  passwordIcon: (theme: Theme): SystemStyleObject<Theme> => ({
    color: theme.palette.text.primary,
  }),
};

export default offchainLoginFormStyles;
